import React from "react";
import { Link } from "gatsby";

import logo from "../img/logo.svg";
import facebook from "../img/social/facebook.svg";
import instagram from "../img/social/instagram.svg";
import twitter from "../img/social/twitter.svg";
import vimeo from "../img/social/vimeo.svg";
import youTube from "../img/social/icon-youtube.svg";
import patreon from "../img/social/patreon.svg";
import { Helmet } from "react-helmet";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <h1 style={{ color: "white", paddingTop: "1rem" }}>
            Фондация Македония
          </h1>
          <div className="container has-background-black has-text-white-ter">
            <div style={{ maxWidth: "100vw" }} className="columns">
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link to="/" className="navbar-item">
                        Начало
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/about">
                        За нас
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/blog">
                        Кампании
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4">
                <section>
                  <ul className="menu-list">
                    {/* <li>
                      <Link className="navbar-item" to="/activists">
                        Активисти
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link className="navbar-item" to="/reports">
                        Отчет
                      </Link>
                    </li> */}
                    <li>
                      <Link className="navbar-item" to="/news">
                        Новини
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/charter">
                        Устав
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/contact">
                        Контакт
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4 social">
                <a
                  title="facebook"
                  href="https://www.facebook.com/%D0%A4%D0%BE%D0%BD%D0%B4%D0%B0%D1%86%D0%B8%D1%8F-%D0%9C%D0%B0%D0%BA%D0%B5%D0%B4%D0%BE%D0%BD%D0%B8%D1%8F-100296015559031"
                >
                  <img
                    src={facebook}
                    alt="Facebook"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
                <a
                  title="instagram"
                  href="https://www.instagram.com/macedoniafoundation"
                >
                  <img
                    src={instagram}
                    alt="Instagram"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
                <a
                  title="youtube"
                  href="https://www.youtube.com/channel/UCu7Wk_Cc5yyh73foOms13Qg"
                >
                  <img
                    src={youTube}
                    alt="Instagram"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
                {/* <a
                  title="patreon"
                  href="https://www.patreon.com/foundationmacedonia"
                >
                  <img
                    src={patreon}
                    alt="Instagram"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a> */}
                {/* <a title="twitter" href="https://twitter.com">
                  <img
                    className="fas fa-lg"
                    src={twitter}
                    alt="Twitter"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
                <a title="vimeo" href="https://vimeo.com">
                  <img
                    src={vimeo}
                    alt="Vimeo"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a> */}
                <div style={{ margin: "20px 0 " }}>
                  Фондация Македония е регистрирана в ТРРФЛ с ЕИК 206490940
                </div>
                <div style={{ margin: "20px 0 " }}>
                  Банкова сметка: IBAN BG29FINV91501017536103
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
