import React from "react";
import { Link } from "gatsby";
import github from "../img/github-icon.svg";
import logo from "../img/logo.svg";
import { Helmet } from "react-helmet";

const Navbar = () => {
  const [active, setActive] = React.useState(false);
  // const [open, setOpen] = React.useState(false);
  const [navBarActiveClass, setActiveClass] = React.useState("");
  const toggleHamburger = () => {
    setActive((p) => !p);
  };

  React.useEffect(() => {
    if (active) setActiveClass("is-active");
  }, [active]);

  return (
    <nav
      className="navbar is-transparent"
      role="navigation"
      aria-label="main-navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item" title="Logo">
            <h1 style={{ fontWeight: "bold" }}>Фондация Македония</h1>
            {/* <a
              href="https://www.patreon.com/bePatron?u=55873826"
              data-patreon-widget-type="become-patron-button"
              onClick={() => {
                window.gtag("event", "patreon_button_click");
              }}
            >
              Подкрепете ни в Patreon!
            </a>
            <script
              async
              src="https://c6.patreon.com/becomePatronButton.bundle.js"
            ></script> */}
            {/* <img src={logo} alt="Kaldi" style={{ width: "88px" }} /> */}
          </Link>
          {/* Hamburger menu */}
          <div
            className={`navbar-burger burger ${navBarActiveClass}`}
            data-target="navMenu"
            onClick={() => toggleHamburger()}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div id="navMenu" className={`navbar-menu ${navBarActiveClass}`}>
          <div
            className="navbar-start has-text-centered"
            style={{ width: "100%" }}
          >
            <Link className="navbar-item" to="/about">
              За нас
            </Link>

            <Link
              className="navbar-item"
              to="/blog"
              // onClick={() => setOpen((p) => !p)}
            >
              Кампании
              {/* {open ? "▴" : "▾"} */}
            </Link>
            <Link className="navbar-item" to="/news">
              Новини
            </Link>
            {/* {open && (
              <>
                <Link className="navbar-item" to="/blog/active">
                  Активни целеви кампании
                </Link>
                <Link className="navbar-item" to="/blog/constant">
                  Постоянни кампании
                </Link>
                <Link className="navbar-item" to="/blog/completed">
                  Завършили кампании
                </Link>
              </>
            )} */}
            {/* <Link className="navbar-item" to="/blog">
                Новини
              </Link> */}
            {/* <Link className="navbar-item" to="/contact/examples">
                Събития
              </Link> */}
            {/* <Link className="navbar-item" to="/activists">
              Активисти
            </Link>
            <Link className="navbar-item" to="/reports">
              Отчет
            </Link> */}
            <Link className="navbar-item" to="/charter">
              Устав
            </Link>
            <Link className="navbar-item" to="/contact">
              Контакти
            </Link>
            <div style={{ flex: 1 }} />
            {/* <div className="navbar-item">
              <div
                style={{
                  backgroundColor: "rgb(255, 66, 77)",
                  display: "flex",
                  alignItems: "center",
                  padding: "7.5px 16px",
                  borderRadius: 30,
                  width: 200,
                }}
              >
                <div style={{ width: 20, height: 20, marginRight: 8 }}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 180">
                    <path
                      fill="white"
                      d="M108.8135992 26.06720125c-26.468266 0-48.00213212 21.53066613-48.00213212 47.99733213 0 26.38653268 21.53386613 47.85426547 48.00213213 47.85426547 26.38639937 0 47.8530655-21.4677328 47.8530655-47.85426547 0-26.466666-21.46666613-47.99733213-47.85306547-47.99733213"
                    />
                    <path
                      fill="white"
                      d="M23.333335 153.93333178V26.0666679h23.46666576v127.8666639z"
                    />
                  </svg>
                </div>
                <a
                  href="https://www.patreon.com/bePatron?u=55873826"
                  style={{ color: "white" }}
                >
                  Стани благодател!
                </a>
              </div>
            </div> */}
            {/* <div className="navbar-item">
              <form
                action="https://www.paypal.com/donate"
                method="post"
                target="_top"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  type="hidden"
                  name="hosted_button_id"
                  value="LCXJPHSP22A8G"
                />
                <input
                  type="image"
                  src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif"
                  border="0"
                  name="submit"
                  title="PayPal - The safer, easier way to pay online!"
                  alt="Donate with PayPal button"
                />
                <img
                  alt=""
                  border="0"
                  src="https://www.paypal.com/en_BG/i/scr/pixel.gif"
                  width="1"
                  height="1"
                />
              </form>
            </div> */}
          </div>
          {/* <div className="navbar-end has-text-centered">
              <div style={{ display: "flex", alignItems: "center" }}>
                +359899384100
              </div>
            </div> */}
        </div>
      </div>
      {/* <Helmet>
        <script src="https://c6.patreon.com/becomePatronButton.bundle.js"></script>
      </Helmet> */}
    </nav>
  );
};

export default Navbar;
